@import url(https://fonts.googleapis.com/css2?family=Zen+Dots&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.header{
  background: url(/static/media/computer.94de1fb9.jpg);
  background-repeat: no-repeat;
  background-size: cover;
}
body{
  background-color: rgb(47,47,47);
  color:white;
}
html {
  scroll-behavior: smooth;
}

.navbar {
  height: 60px;
  background-color: #292929;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
  position: fixed;
  width: 100%;
  z-index: 15;
}

@media (max-width: 768px) {
  .navbar {
    display: block;
    text-align: right;
  }
}

.navbar__menulogo {
  z-index: 10;
}

@media (min-width: 768px) {
  .navbar__menulogo {
    display: none;
  }
}

@media (max-width: 768px) {
  .navbar__menulogo {
    padding-top: 0.7rem;
    padding-right: 2rem;
  }
}

.navbar__links {
  display: flex;
  list-style: none;
  height: 100%;
  width: 50%;
  justify-content: space-around;
  align-items: center;
  margin-left: auto;
}

.navbar__links li div {
  color: white;
  text-decoration: none;
  font-size: 20px;
}

.navbar__links li div:hover {
  cursor: pointer;
  color: #ffb633;
}

@media (max-width: 768px) {
  .navbar__links {
    position: fixed;
    background-color: #2f2f2f;
    height: 100vh;
    width: 100%;
    flex-direction: column;
    clip-path: circle(100px at 90% -10%);
    -webkit-clip-path: circle(100px at 90% -10%);
    transition: all 1s ease-out;
    z-index: 2;
  }
}

.navbar__links.open {
  clip-path: circle(1000px at 90% -10%);
  -webkit-clip-path: circle(1000px at 90% -10%);
}

@media (max-width: 768px) {
  .navbar__links.open {
    clip-path: circle(1000px at 90% -10%);
    -webkit-clip-path: circle(1000px at 90% -10%);
  }
}

nav {
  position: relative;
}
/*# sourceMappingURL=navbar.css.map */
.aboutme {
  padding-top: 60px;
  background-color: #2f2f2f;
  color: white;
}

.aboutme__container__title {
  font-size: 1.7rem;
  color: #f7f7f7;
  text-align: center;
  display: table;
  position: relative;
  margin: 0px auto 72px;
  margin-top: 0px;
  font-weight: bold;
}

.aboutme__container__title h2::before {
  bottom: 20px;
  left: -11px;
  content: "";
  display: block;
  position: absolute;
  background: #ffb633 none repeat scroll 0% 0%;
  width: 80%;
  height: 3px;
  border-radius: 50px;
  transition: left 0.5s ease-in-out 0s, right 0.5s ease-in-out 0s, opacity 0.5s ease-in-out 0s;
  opacity: 1;
}

.aboutme__container__title h2::after {
  content: "";
  bottom: 6px;
  right: -12px;
  display: block;
  position: absolute;
  background: #ffb633 none repeat scroll 0% 0%;
  width: 80%;
  height: 3px;
  border-radius: 50px;
  transition: left 0.5s ease-in-out 0s, right 0.5s ease-in-out 0s, opacity 0.5s ease-in-out 0s;
  opacity: 1;
}

.aboutme__container__description {
  padding-left: 0.8rem;
  padding-right: 0.8rem;
  padding-bottom: 0.5rem;
  line-height: 1.75;
  opacity: 1;
  transform: translateY(0px);
  transition: opacity 0.5s ease 0s, transform 0.5s ease 0s;
  text-align: center;
  font-size: 20px;
}

@media (min-width: 992px) {
  .aboutme__container__description {
    font-size: 1.8rem;
    padding: 2rem;
  }
}
/*# sourceMappingURL=aboutme.css.map */
.footer {
  padding-top: 60px;
}

.footer__container__title {
  font-size: 1.7rem;
  color: #f7f7f7;
  text-align: center;
  display: table;
  position: relative;
  margin: 0px auto 72px;
  margin-top: 0px;
  font-weight: bold;
}

.footer__container__title h2::before {
  bottom: 20px;
  left: -11px;
  content: "";
  display: block;
  position: absolute;
  background: #ffb633 none repeat scroll 0% 0%;
  width: 80%;
  height: 3px;
  border-radius: 50px;
  transition: left 0.5s ease-in-out 0s, right 0.5s ease-in-out 0s, opacity 0.5s ease-in-out 0s;
  opacity: 1;
}

.footer__container__title h2::after {
  content: "";
  bottom: 6px;
  right: -12px;
  display: block;
  position: absolute;
  background: #ffb633 none repeat scroll 0% 0%;
  width: 80%;
  height: 3px;
  border-radius: 50px;
  transition: left 0.5s ease-in-out 0s, right 0.5s ease-in-out 0s, opacity 0.5s ease-in-out 0s;
  opacity: 1;
}

.footer__container__boxes {
  display: flex;
  flex-direction: column;
  margin-left: 1rem;
  margin-right: 1rem;
}

@media (min-width: 768px) {
  .footer__container__boxes {
    flex-direction: row;
    justify-content: space-around;
  }
}

.footer__container__boxes__mail {
  cursor: pointer;
}

.footer__container__boxes__mail {
  padding: 1rem;
  border: 2px solid #ffb633;
  background-color: #272727;
  margin-bottom: 2rem;
}

@media (min-width: 768px) {
  .footer__container__boxes__mail {
    width: 45%;
  }
}

.footer__container__boxes__mail__icon {
  margin-bottom: 1rem;
}

.footer__container__boxes__mail__emailaddress {
  margin-bottom: 2rem;
  font-size: 1.5rem;
  transition: 0.5s;
}

.footer__container__boxes__mail:hover {
  cursor: pointer;
}

.footer__container__boxes__mail:hover > .footer__container__boxes__mail__emailaddress {
  color: #ffb633;
}

.footer__container__boxes__github:hover {
  cursor: pointer;
}

.footer__container__boxes__github:hover > .footer__container__boxes__github__emailaddress {
  color: #ffb633;
}

.footer__container__boxes__github {
  border: 2px solid #ffb633;
  background-color: #272727;
  margin-bottom: 2rem;
}

@media (min-width: 768px) {
  .footer__container__boxes__github {
    width: 45%;
  }
}

.footer__container__boxes__github__icon {
  margin-bottom: 1rem;
}

.footer__container__boxes__github__emailaddress {
  margin-bottom: 2rem;
  font-size: 1.5rem;
  transition: 0.5s;
}
/*# sourceMappingURL=footer.css.map */
.header__particles {
  left: 0px;
  top: 60px;
  width: 0px;
  height: 0px;
  border-top: 150px solid rgba(255, 182, 51, 0.8);
  border-right: 250px solid transparent;
}

.header__container::before {
  left: 0px;
  top: 0px;
  width: 0px;
  height: 0px;
  border-top: 150px solid #ffb633;
  border-right: 250px solid transparent;
}

.header__container {
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7);
  position: relative;
}

.header__container__titles {
  position: absolute;
  top: 30%;
  left: 0;
  right: 0;
  color: white;
  pointer-events: none;
}

.header__container__titles__title {
  font-size: 2rem;
  color: white;
  font-weight: 700;
  margin-bottom: 1.5rem;
}

.header__container__titles__title span {
  position: relative;
  font-size: 2rem;
  color: white;
  -webkit-text-stroke: 0.3vw transparent;
  text-transform: uppercase;
}

@media (min-width: 992px) {
  .header__container__titles__title span {
    font-size: 4rem;
  }
}

@media (min-width: 1200px) {
  .header__container__titles__title span {
    font-size: 5rem;
  }
}

.header__container__titles__title span::before {
  content: attr(data-text);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  color: #ffb633;
  -webkit-text-stroke: 0vw #383d52;
  border-right: 2px solid #ffb633;
  overflow: hidden;
  animation: animate 6s linear infinite;
}

@keyframes animate {
  0%, 10%, 100% {
    width: 0;
  }
  70%, 90% {
    width: 100%;
  }
}

.header__container__titles__secondtitle {
  font-size: 1.8rem;
  color: white;
  font-weight: 700;
  margin-bottom: 1.5rem;
}

.header__container__titles__secondtitle span {
  position: relative;
  font-size: 2rem;
  color: white;
  -webkit-text-stroke: 0.3vw transparent;
  text-transform: uppercase;
  white-space: nowrap;
}

@media (min-width: 992px) {
  .header__container__titles__secondtitle span {
    font-size: 4rem;
  }
}

@media (min-width: 1200px) {
  .header__container__titles__secondtitle span {
    font-size: 5rem;
  }
}

.header__container__titles__secondtitle span::before {
  content: attr(data-text);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  color: #ffb633;
  -webkit-text-stroke: 0vw #383d52;
  border-right: 2px solid #ffb633;
  overflow: hidden;
  animation: animate 6s linear infinite;
}

.header__container__titles__thirdtitle {
  color: white;
  font-weight: 700;
  margin-bottom: 2rem;
}

.header__container__titles__thirdtitle__title {
  font-size: 1.6rem;
  color: #ffb633;
  margin-bottom: 1rem;
}

@media (min-width: 992px) {
  .header__container__titles__thirdtitle__title {
    font-size: 3rem;
  }
}

@media (min-width: 1200px) {
  .header__container__titles__thirdtitle__title {
    font-size: 4rem;
  }
}

.header__container__titles__thirdtitle__technologies {
  font-size: 1.4rem;
  margin-bottom: 1.5rem;
}

.header__container__titles__thirdtitle__technologies span.verticalline {
  color: #ffb633;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

@media (min-width: 992px) {
  .header__container__titles__thirdtitle__technologies {
    font-size: 2rem;
  }
}

@media (min-width: 1200px) {
  .header__container__titles__thirdtitle__technologies {
    font-size: 2.5rem;
  }
}

.header__container__titles__thirdtitle__myprojects button {
  background-color: transparent;
  color: white;
  padding: 1rem;
  font-size: 1.2rem;
  font-weight: 700;
  border: 1px solid brown;
  transition: 0.5s;
}

@media (min-width: 992px) {
  .header__container__titles__thirdtitle__myprojects button {
    font-size: 1.5rem;
  }
}

@media (min-width: 1200px) {
  .header__container__titles__thirdtitle__myprojects button {
    font-size: 1.8rem;
  }
}

.header__container__titles__thirdtitle__myprojects button:hover {
  background-color: #ffb633;
  cursor: pointer;
}
/*# sourceMappingURL=header.css.map */
.projects {
  padding-top: 60px;
  padding-left: 2rem;
  padding-right: 2rem;
}

.projects__container__title {
  font-size: 1.8rem;
  color: #f7f7f7;
  text-align: center;
  display: table;
  position: relative;
  margin: 0px auto 72px;
  margin-top: 0px;
  font-weight: bold;
}

.projects__container__title h2::before {
  bottom: 20px;
  left: -11px;
  content: "";
  display: block;
  position: absolute;
  background: #ffb633 none repeat scroll 0% 0%;
  width: 80%;
  height: 3px;
  border-radius: 50px;
  transition: left 0.5s ease-in-out 0s, right 0.5s ease-in-out 0s, opacity 0.5s ease-in-out 0s;
  opacity: 1;
}

.projects__container__title h2::after {
  content: "";
  bottom: 6px;
  right: -12px;
  display: block;
  position: absolute;
  background: #ffb633 none repeat scroll 0% 0%;
  width: 80%;
  height: 3px;
  border-radius: 50px;
  transition: left 0.5s ease-in-out 0s, right 0.5s ease-in-out 0s, opacity 0.5s ease-in-out 0s;
  opacity: 1;
}

.projects__container__elements {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.projects__container__element {
  width: 100%;
  height: 100%;
  overflow: hidden;
  border: 4px solid #ffb633;
  margin-bottom: 2rem;
  min-width: 300px;
  max-width: 400px;
  min-height: 550px;
  display: flex;
  flex-direction: column;
}

@media (min-width: 768px) {
  .projects__container__element {
    margin-right: 2rem;
  }
}

.projects__container__element__title {
  font-size: 1.8rem;
  font-weight: 700;
  padding: 1rem;
  border-bottom: 2px solid white;
  background-color: #272727;
  font-family: 'Zen Dots', cursive;
}

.projects__container__element__image {
  display: flex;
  width: 100%;
  height: 215px;
  overflow: hidden;
}

.projects__container__element__image--img {
  overflow: hidden;
  transition: transform 0.5s linear;
}

.projects__container__element__image--img:hover {
  transform: scale(1.1);
}

.projects__container__element__image img {
  transition: transform 0.5s linear;
  width: 100%;
  height: 100%;
}

.projects__container__element__description {
  display: flex;
  justify-content: center;
  padding: 1rem;
  text-align: center;
  background-color: white;
  color: #272727;
  font-weight: 600;
  flex: 1 1;
  align-items: center;
}

.projects__container__element__buttons__livebutton:hover {
  cursor: pointer;
}

.projects__container__element__buttons__livebutton {
  background: none;
  border-top: 2px solid white;
  font-size: 1.3rem;
  padding: 0.7rem;
  font-weight: 600;
  transition: 0.8s;
  position: relative;
  overflow: hidden;
}

.projects__container__element__buttons__livebutton::before {
  content: "";
  position: absolute;
  left: 0;
  width: 100%;
  height: 0%;
  background: #ffb633;
  z-index: -1;
  transition: 0.8s;
  top: 0;
  border-radius: 0 0 50% 50%;
}

.projects__container__element__buttons__livebutton:hover::before {
  height: 180%;
}

.projects__container__element__buttons__githubbutton:hover {
  cursor: pointer;
}

.projects__container__element__buttons__githubbutton::before {
  content: "";
  position: absolute;
  left: 0;
  width: 100%;
  height: 0%;
  background: #ffb633;
  z-index: -1;
  transition: 0.8s;
  top: 0;
  border-radius: 0 0 50% 50%;
}

.projects__container__element__buttons__githubbutton:hover::before {
  height: 180%;
}

.projects__container__element__buttons__githubbutton {
  background: none;
  border-top: 2px solid white;
  font-size: 1.3rem;
  padding: 0.7rem;
  font-weight: 600;
  transition: 0.8s;
  position: relative;
  overflow: hidden;
}

.projects__container__element__buttons__githubbutton img {
  width: 23px;
}
/*# sourceMappingURL=projects.css.map */
.technologies {
  background-color: #2f2f2f;
  padding-top: 60px;
}

.technologies__container__title {
  margin-top: 2rem;
  font-size: 1.3rem;
  color: #f7f7f7;
  text-align: center;
  display: table;
  position: relative;
  margin: 0px auto 72px;
  margin-top: 0px;
  font-weight: bold;
}

.technologies__container__title h2::before {
  bottom: 20px;
  left: -11px;
  content: "";
  display: block;
  position: absolute;
  background: #ffb633 none repeat scroll 0% 0%;
  width: 80%;
  height: 3px;
  border-radius: 50px;
  transition: left 0.5s ease-in-out 0s, right 0.5s ease-in-out 0s, opacity 0.5s ease-in-out 0s;
  opacity: 1;
}

.technologies__container__title h2::after {
  content: "";
  bottom: 6px;
  right: -12px;
  display: block;
  position: absolute;
  background: #ffb633 none repeat scroll 0% 0%;
  width: 80%;
  height: 3px;
  border-radius: 50px;
  transition: left 0.5s ease-in-out 0s, right 0.5s ease-in-out 0s, opacity 0.5s ease-in-out 0s;
  opacity: 1;
}

.technologies__container__secondtitle {
  margin-top: 2rem;
  font-size: 1.3rem;
  color: #f7f7f7;
  text-align: center;
  display: table;
  position: relative;
  margin: 0px auto 72px;
  margin-top: 0px;
  font-weight: bold;
}

.technologies__container__secondtitle h2::before {
  bottom: 20px;
  left: -11px;
  content: "";
  display: block;
  position: absolute;
  background: #ffb633 none repeat scroll 0% 0%;
  width: 80%;
  height: 3px;
  border-radius: 50px;
  transition: left 0.5s ease-in-out 0s, right 0.5s ease-in-out 0s, opacity 0.5s ease-in-out 0s;
  opacity: 1;
}

.technologies__container__secondtitle h2::after {
  content: "";
  bottom: 6px;
  right: -12px;
  display: block;
  position: absolute;
  background: #ffb633 none repeat scroll 0% 0%;
  width: 80%;
  height: 3px;
  border-radius: 50px;
  transition: left 0.5s ease-in-out 0s, right 0.5s ease-in-out 0s, opacity 0.5s ease-in-out 0s;
  opacity: 1;
}

.technologies__container__element:hover {
  cursor: pointer;
}

.technologies__container__elements {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.technologies__container__element:hover {
  box-shadow: 0 0 6px 6px #ffb633;
}

.technologies__container__element {
  border: 2px solid #ffb633;
  padding: 1rem;
  border-radius: 60px;
  margin-left: 1rem;
  margin-right: 1rem;
  margin-bottom: 2rem;
  background-color: #272727;
  opacity: 1;
  transform: translateX(0px);
  transition: opacity 0.5s ease 0s, transform 0.5s ease 0s;
  box-shadow: 0 0 2px 2px #ffb633;
}

@media (min-width: 576px) {
  .technologies__container__element {
    width: 420px;
  }
}

.technologies__container__element__title {
  padding-top: 2rem;
  font-size: 2rem;
  /*text-transform: uppercase;*/
}

.technologies__container__element__description {
  margin-top: 2rem;
  font-size: 1.5rem;
}

.technologies__container__element__image img {
  width: 200px;
  height: 200px;
}

.technologies__container__restelement {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 1rem;
}

@media (min-width: 992px) {
  .technologies__container__restelement {
    padding-left: 2rem;
  }
}

.technologies__container__restelement__title {
  padding: 1rem;
  border: 2px solid #ffb633;
  margin-bottom: 1rem;
  margin-right: 1rem;
  transition: 0.5s;
}

@media (min-width: 992px) {
  .technologies__container__restelement__title {
    font-size: 1.3rem;
  }
}

.technologies__container__restelement__title:hover {
  background-color: #ffb633;
  cursor: pointer;
  transform: perspective(500px) rotateY(30deg);
  text-shadow: -6px 3px 2px rgba(0, 0, 0, 0.2);
  box-shadow: -2px 0 0 5px rgba(0, 0, 0, 0.2);
}
/*# sourceMappingURL=technologies.css.map */
